import React, { useEffect, useState } from "react";
import axios from "axios";

function ModelTypes() {
  const [apiData, setApiData] = useState("");
  useEffect(() => {
    async function callApi() {
      axios
        .get(
          "https://bot.assisto.tech/dashboard_language_integration/model_type"
        )
        .then((response) => {
          console.log(response);
          setApiData(response.data.model_types);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
    callApi();
  }, []);
  return (
    <div className="container">
      <h1>Model Types</h1>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Sno</th>
            <th>Model Type Name</th>
          </tr>
        </thead>
        <tbody>
          {apiData &&
            apiData.map((apiDetails, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{apiDetails}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ModelTypes;
