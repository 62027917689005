import React, { useEffect, useState } from "react";
import axios from "axios";

function ConfigOptions() {
  const [apiData, setApiData] = useState("");
  const [apiModelData, setApiModelData] = useState("");
  const [model_type, setModelType] = useState("albert");
  const Params = JSON.stringify({ model_type: `${model_type}` });
  const headers = {
    "Content-Type": "application/json",
  };
  async function getModelTypesApi() {
    axios
      .get("https://bot.assisto.tech/dashboard_language_integration/model_type")
      .then((response) => {
        setApiModelData(response.data.model_types);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }
  getModelTypesApi();

  useEffect(() => {
    async function callApi() {
      axios
        .post(
          "https://bot.assisto.tech/dashboard_language_integration/config_options",
          Params,
          { headers }
        )
        .then((response) => {
          const arr = [];
          Object.keys(response.data.config_options).forEach((key) =>
            arr.push([key, response.data.config_options[key]])
          );
          setApiData(arr);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
    callApi();
  }, [Params]);
  return (
    <div className="container">
      <h1>Config Options</h1>
      <form>
        <label className="form-label">
          Config Option:
          <select
            id="config"
            type="select"
            value={model_type}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
            onChange={(e) => {
              setModelType(e.target.value);
            }}
          >
            {apiModelData &&
              apiModelData.map((apiDetails, index) => {
                return (
                  <option key={index} value={apiDetails}>
                    {apiDetails}
                  </option>
                );
              })}
          </select>
        </label>
      </form>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Sno</th>
            <th>Key</th>
            <th>Value</th>
            <th>Data Type</th>
          </tr>
        </thead>
        <tbody>
          {apiData &&
            apiData.map((apidetails, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{apidetails[0]}</td>
                  <td>{apidetails[1]}</td>
                  <td>{typeof apidetails[1]}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ConfigOptions;
