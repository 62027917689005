import React from "react";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ModelTypes from "./components/ModelTypes.js";
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import PageNotFound from "./components/PageNotFound";
import MaskModelling from "./components/MaskModelling";
import ConfigOptions from "./components/ConfigOptions";
import ModelDistillation from "./components/ModelDistillation";

function App() {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/maskmodelling" element={<MaskModelling />} />
            <Route path="/modeltypes" element={<ModelTypes />} />
            <Route path="/configoptions" element={<ConfigOptions />} />
            <Route path="/modeldistillation" element={<ModelDistillation />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
