import React from "react";

function PageNotFound() {
  return (
    <div className="container">
      <h1>404 Page Not Found</h1>
      We're working hard to put out new features for your lost URLs. There will
      be many more stories and trendy images to come in the future.
    </div>
  );
}

export default PageNotFound;
