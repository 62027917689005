import React, { useEffect, useState } from "react";
import axios from "axios";

function ModelDistillation() {
  const [apiLanguageData, setApiLanguageData] = useState("");
  const [output_dir, setOutputDir] = useState("");
  const [lang_code, setLangCode] = useState("");
  const [train_file_same, setSameFile] = useState("");
  const [train_file_similar, setSimilarFile] = useState("");
  const [train_file_dissimilar, setDissimilarFile] = useState("");

  function ValidateSingleInput(oInput) {
    //if (oInput.type == "file") {
    const _validFileExtensions = [".csv"];
    const sFileName = oInput;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(
          "Sorry, " +
            sFileName +
            " is invalid, allowed extensions are: " +
            _validFileExtensions.join(", ")
        );
        //oInput.value = "";
        return false;
      }
      //}
    }
    return true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    ValidateSingleInput(train_file_same);
    ValidateSingleInput(train_file_similar);
    ValidateSingleInput(train_file_dissimilar);
    const headers = "";
    const formData = new FormData();
    formData.append("output_dir", { output_dir });
    formData.append("lang_code", { lang_code });
    formData.append("train_file_same", { train_file_same });
    formData.append("train_file_similar", { train_file_similar });
    formData.append("train_file_dissimilar", { train_file_dissimilar });
    axios
      .get(
        "https://bot.assisto.tech/dashboard_language_integration/model_distillation",
        { formData }
      )
      .then((response) => {
        console.log(response);
        setApiLanguageData(response);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  return (
    <div className="container">
      <h1>Model Distillation</h1>
      <form onSubmit={handleSubmit} className="row g-3">
        <label className="form-label">
          Output Directory
          <input
            id="output_dir"
            type="text"
            className="form-control"
            placeholder="Output Directory Path"
            aria-label="Output Directory Path"
            value={output_dir}
            onChange={(e) => setOutputDir(e.target.value)}
          />
        </label>
        <label className="form-label">
          Language Code
          <input
            id="lang_code"
            type="text"
            className="form-control"
            placeholder="Language Code"
            aria-label="Language Code"
            value={lang_code}
            onChange={(e) => setLangCode(e.target.value)}
          />
        </label>
        <label className="form-label">
          Training Same File:
          <input
            id="train_file_same"
            type="file"
            className="form-control"
            value={train_file_same}
            onChange={(e) => {
              setSameFile(e.target.value);
            }}
          />
        </label>
        <label className="form-label">
          Training Similar File:
          <input
            id="train_file_similar"
            type="file"
            className="form-control"
            value={train_file_similar}
            onChange={(e) => {
              setSimilarFile(e.target.value);
            }}
          />
        </label>
        <label className="form-label">
          Training Dissimilar File:
          <input
            id="train_file_dissimilar"
            type="file"
            className="form-control"
            value={train_file_dissimilar}
            onChange={(e) => {
              setDissimilarFile(e.target.value);
            }}
          />
        </label>
        <input type="submit" className="btn btn-primary" />
      </form>
      <div className="container">
        <h1>Response</h1>
        {apiLanguageData}
      </div>
    </div>
  );
}

export default ModelDistillation;
