import React from "react";

function Home() {
  return (
    <div className="container">
      <h1>Developer Dashboard - Home</h1>
<p>This collection is made to easily integrate end-to-end a new language with chatbot. APIs in this collection should be run in order as they are given to complete the process.</p>
<h2>1. Train language identification</h2>
A text file with at least 500000 line in the language of your choice will be uploaded. Additionally language name and language code will be supplied. After successful training, we will proceed to the next step.
<h2>2. Mask Language Modelling</h2>
<p>Next step in the pipeline will be mask language modelling. For that, two additional APIs will be required to be called.
2.1 Model Types: Model types will provide the list of all the models available to train the model for MLM. User will select one of these model which will be passed to the next API call.
2.2. Config Options : Model type selected in previous step will be sent to another API to retrieve all the configuration option pertaining to the selected model. User will be allowed to override one or more options . Whether user changes configuration or not, these configuration options will be sent to the next API call in exact same format as you receive with changed values.
2.3 Mask Language Modelling : As a third and final step, the mask language modelling API will be called with model type and config options and three files. Both train file and dev file are the txt. files with one sentence(or a paragraph) separated with other line (or paragraph) with "\n" (newline). As a response to success training, you will receive output directory where model will be saved and language code. These two will be used in next step.</p>
<h2>3. Model Distillation</h2>
<p>Model distillation will be used to further distill the model for better performance. For this, an API with three files, output directory and language code received in the previous step will be called. These files may either be csv(comma separated value) or tsv(tab separated value). In first file, each line will contain sentences which have same meanings (separated by comma or tab). First column of csv (or tsv) may contain any sentence but second column will contain the sentences having exact same meaning as first. Similarly in second file, on each line, we will have sentences from same domain but not necessarily having the same meaning (separated by comma or tab). In last and final file, on each line, we will have sentences with completely different meaning and domain. We can have as many line as we want in each of these files.</p>
</div>
);
}

export default Home;